.Slider {
  align-items: center;
  justify-content: center;
  width: 70%;
  margin: 0 auto 0px auto;
}

.Slider .title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Slider h1 {
  font-size: 1.7rem;
  margin-bottom: 0.5em;
  color: #224f64;
}

.Slider .carousel-container {
  height: 42em;
}

.Slider .card {
  height: 36rem;
  width: 21rem;
  background-color: rgb(241, 245, 255);
  border-radius: 10px;
  padding: 1rem;
  justify-content: Left;
  margin: auto;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  .Slider .card .card-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .card-icon {
    width: 60px;
    background-color: #4089AB;
    padding: 10px;
    border-radius: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.Slider .card:hover {
  background-color: rgb(241, 245, 255);
  box-shadow: 0 0 11px rgba(33, 33, 33, .2);
}

.Slider h4 {
  color: #000;
  margin: 10px 0;
}

.Slider p {
  color: #6d6d6d;
  font-size: 1rem;
  line-height: 1.5;
  height: 300px;
}

.Slider a {
  color: #fff;
  font-size: 0.9rem;
  text-decoration: none;
  padding: 10px 20px;
  background-color: #E70F77;
  width: 100px;
  text-align: center;
  border-radius: 5px;
}

.Slider .custom-dot-list-style button {
  border: none;
}

@media screen and (max-width: 1600px) {
  .Slider {
    width: 80%;
  }

  .Slider .card {
    width: 20rem;
  }

  .Slider h4 {
    margin: 10px 0;
    font-size: 2rem;
  }

  .Slider p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1280px) {
  .Slider {
    width: 80%;
  }

  .Slider .card {
    width: 18rem;
  }

  .Slider h4 {
    margin: 10px 0;
    font-size: 1.7rem;
  }

  .Slider p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .Slider {
    width: 80%;
  }

  .Slider .card {
    width: 22rem;
    // height: 20rem;
  }

  .Slider h4 {
    margin: 10px 0;
    font-size: 1.6rem;
  }

  .Slider p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 540px) {
  .Slider .carousel-container {
    height: 46em;
  }

  .Slider {
    width: 90%;
  }

  .Slider .card {
    width: 18rem;
    height: 39rem;
  }

  .Slider h4 {
    margin: 10px 0;
    font-size: 1.6rem;
  }

  .Slider p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 400px) {
  .Slider .carousel-container {
    height: 46em;
  }

  .Slider {
    width: 88%;
  }

  .Slider .card {
    width: 18rem;
    height: 39rem;
  }

  .Slider h4 {
    margin: 10px 0;
    font-size: 1.6rem;
  }

  .Slider p {
    font-size: 1rem;
  }
}