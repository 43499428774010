.icon-slider {
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0 auto 30px auto;
}

.icon-slider .title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.icon-slider h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5em;
}

.icon-slider .card {
  height: 4em;
  width: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.icon-slider .card:hover {
  transition: all 0.3s ease-in-out;
}

.icon-slider .card img {
  width: 100%;
}

@media (max-width: 768px) {
  .icon-slider {
    width: 80%;
  }

  .icon-slider .title {
    flex-direction: row;
  }

  .icon-slider .card {
    height: 3em;
    width: 3em;
  }
}