.terminal-container {
  width: 450px;
  height: 400px;
  font-family: 'Ubuntu Mono', monospace;

  @media (max-width: 979px) {
    width: 400px;
    height: 400px;
  }

  @media (max-width: 520px) {
    width: 350px;
    height: 350px;
  }
}

.terminal-container .terminal_toolbar {
  display: flex;
  height: 30px;
  align-items: center;
  padding: 0 8px;
  box-sizing: border-box;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #101010;
  justify-content: space-between;
}

.terminal-container .butt {
  display: flex;
  align-items: center;
}

.terminal-container .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-right: 5px;
  font-size: 8px;
  height: 12px;
  width: 12px;
  box-sizing: border-box;
  border: none;
  border-radius: 100%;
  background: linear-gradient(#7d7871 0%, #595953 100%);
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 1px 0px #41403A, 0px 1px 1px 0px #474642;
}

.terminal-container .btn-color {
  background: #ee411a;
}

.terminal-container .btn:hover {
  cursor: pointer;
}

.terminal-container .btn:focus {
  outline: none;
}

.terminal-container .butt--exit {
  background: linear-gradient(#f37458 0%, #de4c12 100%);
}

.terminal-container .add_tab {
  border: 1px solid #fff;
  color: #fff;
  padding: 0 6px;
  border-radius: 4px 4px 0 0;
  border-bottom: none;
  cursor: pointer;
}

.terminal-container .user {
  color: #d5d0ce;
  margin-left: 6px;
  font-size: 14px;
  line-height: 15px;
}

.terminal-container .terminal_body {
  background: rgb(35, 35, 35);
  height: calc(100% - 30px);
  padding-top: 2px;
  margin-top: -1px;
  font-size: 16px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.terminal-container .terminal_promt {
  display: flex;
  margin: 0 0 10px 0;
}

.terminal-container .terminal_promt span {
  margin-left: 4px;
}

.terminal-container .terminal_user {
  color: #1eff8e;
}

.terminal-container .terminal_location {
  color: #4878c0;
}

.terminal-container .terminal_bling {
  color: #dddddd;
}

.terminal-container .terminal_cursor {
  display: block;
  height: 24px;
  width: 5px;
  margin-left: 10px;
  animation: curbl 1200ms linear infinite;
}

@keyframes curbl {

  0% {
    background: #ffffff;
  }

  49% {
    background: #ffffff;
  }

  60% {
    background: transparent;
  }

  99% {
    background: transparent;
  }

  100% {
    background: #ffffff;
  }
}