/*
////////////////////
/// 
Global Styles

_____________________________________________________________________
 */

.title-top {
  font-size: 2rem;
  font-weight: 700;
  margin: 0 0 10px 0;

  @media (max-width: 1280px) {
    font-size: 1.5rem;
  }
}

.title {
  font-size: 3rem;
  font-weight: 700;
  margin: 0 0 20px 0;
  color: #4089AB;

  @media (max-width: 1280px) {
    font-size: 2rem;
  }

  span {
    color: #000;
  }
}

.para {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;

  @media (max-width: 1280px) {
    font-size: 1rem;
  }
}

/*
////////////////////
/// 
Hero

_____________________________________________________________________
 */

.home-page {
  width: 100%;
  overflow: hidden;
}

.home-page .hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: url("./Assets/bg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
}

.home-page .hero h1 {
  font-size: 5rem;
  font-weight: 700;
  width: 50%;
  text-align: center;
  margin-bottom: 10px;

  span {
    font-size: 2rem;
  }
}

.home-page .hero h4 {
  font-size: 1.4rem;
  font-weight: 400;
  width: 50%;
  text-align: center;
  margin-bottom: 20px;
  line-height: 1.5;
}

.home-page .hero .hero-buttons {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.home-page .hero .hero-buttons a {
  text-decoration: none;
  color: #fff;
  padding: 15px 30px;
  border-radius: 50px;
  background-color: #4089AB;
  transition: all 0.3s ease;
  font-size: 1.2rem;
}

.home-page .hero .hero-buttons a:hover {
  background-color: #fff;
  color: #4089AB;
  transition: all 0.3s ease;
}

.home-page .hero .hero-buttons .border-btn {
  border: 1px solid #4089AB;
  background-color: transparent;
}

.home-page .hero .hero-buttons .border-btn:hover {
  background-color: #4089AB;
  color: #fff;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .home-page .hero h1 {
    font-size: 3rem;
    width: 80%;
  }

  .home-page .hero h4 {
    font-size: 1rem;
    width: 80%;
  }
}

/* 
///////////////

About

_____________________________________________________________________
 */
.home-page {
  .about {
    padding: 30px 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ant-tabs-tab-btn {
      font-size: 1rem;
      color: #333;
    }

    .tab {
      p {
        font-size: 1rem;
        line-height: 1.5;
        margin: 0;
      }
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #4089AB;
    }

    .ant-tabs-ink-bar {
      background-color: #4089AB;
    }

    @media (max-width: 1280px) {
      padding: 50px 100px;
    }

    @media (max-width: 979px) {
      padding: 50px 50px;
    }

    @media (max-width: 768px) {
      padding: 30px;
      flex-direction: column;
    }

    .about-left {
      width: 50%;

      @media (max-width: 979px) {
        width: 70%;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .about-right {
      @media (max-width: 768px) {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 100%;

        @media (max-width: 768px) {
          width: 80%;
        }
      }
    }
  }
}

/*
////////////////////

Services

_____________________________________________________________________
 */

.home-page {
  .services {

    .services-inner {
      text-align: center;
      padding: 0 20px;

      @media screen and (max-width: 768px) {
        padding: 0 20px;
      }

      @media screen and (max-width: 400px) {
        padding: 0 10px;
      }
    }

    .react-multi-carousel-track {
      margin-bottom: 70px;
    }

    .react-multi-carousel-dot-list {
      bottom: 40px;
    }
  }
}

/*
////////////////////

Technologies

_____________________________________________________________________
 */
.home-page {
  .hero-technologies {
    padding: 0 0 20px 0;

    .hero-technologies-up {
      padding: 50px 0;
      background-image: url("./Assets/technologies-bg.webp");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 4em;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @media screen and (max-width: 768px) {
        padding: 30px 0;
      }

      h3 {
        color: #fff;
      }
    }

    .hero-technologies-bottum {
      padding: 30px 0;
      border-bottom: 1px solid #ddd;
    }
  }
}




/*
////////////////////

Team

_____________________________________________________________________
 */

.home-page {
  .home-team {
    padding: 10px 200px 30px 200px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1280px) {
      padding: 30px 100px;
    }

    @media (max-width: 979px) {
      padding: 30px 50px;
    }

    @media (max-width: 768px) {
      padding: 20px;
      flex-direction: column;
    }

    .home-team-left {
      width: 100%;

      .home-team-cards {
        display: flex;
        gap: 20px;

        @media (max-width: 1024px) {
          flex-wrap: wrap;
        }


        .card {
          position: relative;
          max-width: 250px;
          height: 350px;
          border-radius: 10px;
          padding: 1rem;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          gap: 7.5px;
          transition: 0.5s ease;
          color: white;
          background: #000;
        }

        .card1::after {
          content: "";
          position: absolute;
          inset: 0;
          background: url('./Assets/team/gimhana.webp');
          background-position: center;
          background-size: cover;
          transition: 0.3s ease;
        }

        .card2::after {
          content: "";
          position: absolute;
          inset: 0;
          // background: url('./Assets/team/sharanka.webp');
          background-color: #999;
          background-position: center;
          background-size: cover;
          transition: 0.3s ease;
        }

        .card3::after {
          content: "";
          position: absolute;
          inset: 0;
          background: url('./Assets/team/aruna.webp');
          background-position: center;
          background-size: cover;
          transition: 0.3s ease;
        }

        .card:hover::after {
          opacity: 0.5;
        }

        .cardHeader {
          text-transform: uppercase;
          position: relative;
          width: max-content;
          font-weight: bold;
          transition: all 0.5s ease;

          h2 {
            font-size: 1.5rem;
            margin: 0;
          }

          h4 {
            font-size: 1rem;
            margin: 0 0 10px 0;
          }
        }

        .cardHeader::after {
          content: "";
          width: calc(100% + 1rem);
          height: 2.5px;
          transform: translateX(calc(-100% - 1rem));
          background: #4089AB;
          bottom: -2px;
          left: 0;
          position: absolute;
          opacity: 0;
        }

        .details_1 {
          z-index: 2;
          display: flex;
          flex-direction: column;
          gap: 5px;
          transform: translateY(78%);
          transition: all 0.5s ease;
        }

        .details_2 {
          z-index: 2;
          display: flex;
          flex-direction: column;
          gap: 5px;
          transform: translateY(82%);
          transition: all 0.5s ease;
        }

        .details_3 {
          z-index: 2;
          display: flex;
          flex-direction: column;
          gap: 5px;
          transform: translateY(74%);
          transition: all 0.5s ease;
        }

        .card:hover .details_1 {
          transform: translateY(0%);
          transition-delay: 0.5s;
        }

        .card:hover .details_2 {
          transform: translateY(0%);
          transition-delay: 0.5s;
        }

        .card:hover .details_3 {
          transform: translateY(0%);
          transition-delay: 0.5s;
        }

        .card:hover .cardHeader::after {
          transform: translateX(-1rem);
          transition: 0.5s ease;
          opacity: 1;
        }

        .card:hover {
          transform: scale(1.05);
          border-radius: 15px;
        }

        @media (max-width: 768px) {
          justify-content: center;
        }

        .home-team-cards-item {
          border-radius: 100%;
          overflow: hidden;
          transition: all 0.3s ease;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

          @media (max-width: 768px) {
            margin: 0 auto;
            margin-bottom: 20px;
          }

          .card-details {
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            h2 {
              font-size: 1rem;
              margin: 0;
              margin-bottom: 10px;
            }

            div {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;

              a {
                color: #fff;
                text-decoration: none;
                font-size: 1.2rem;
                transition: all 0.3s ease;

                &:hover {
                  color: #e70f77;
                  transform: scale(1.1);
                  transition: all 0.3s ease;
                  z-index: 1000;
                }
              }
            }

            @media (max-width: 1280px) {
              h2 {
                font-size: 1rem;
              }

              div {
                a {
                  font-size: 1rem;
                }
              }
            }

            @media (max-width: 768px) {
              h2 {
                font-size: 0.5rem;
                margin: 0;
              }

              div {
                a {
                  font-size: 1rem;
                }
              }
            }
          }

          &:hover {
            cursor: pointer;
            transition: all 0.3s ease;
            z-index: 1000;
          }
        }

        // chnage position of cards
        .home-team-cards-item:nth-child(1) {
          transform: scale(1) translate(0px, 0px);

          &:hover {
            transform: scale(1.2) translate(0px, 0px);
          }
        }

        .home-team-cards-item:nth-child(2) {
          transform: scale(1) translate(-20px, 0px);

          &:hover {
            transform: scale(1.2) translate(-20px, 0px);
          }
        }

        .home-team-cards-item:nth-child(3) {
          transform: scale(1) translate(-40px, 0px);

          &:hover {
            transform: scale(1.2) translate(-40px, 0px);
          }
        }

        .home-team-cards-item:nth-child(4) {
          transform: scale(1) translate(-60px, 0px);

          &:hover {
            transform: scale(1.2) translate(-60px, 0px);
          }
        }
      }
    }

    .home-team-right {
      margin-top: 10px;
      width: 30%;

      @media (max-width: 1280px) {
        width: 50%;
      }

      @media (max-width: 768px) {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

/*
////////////////////

Contact

_____________________________________________________________________
 */

.getintouch {
  padding: 50px 200px 100px 200px;

  @media (max-width: 1400px) {
    padding: 100px 100px;
  }

  @media (max-width: 800px) {
    padding: 60px 20px;
  }

  .main-title {
    margin: 0 auto;
    width: fit-content;
  }

  .getintouch-container {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: start;

    @media (max-width: 800px) {
      flex-direction: column;
      margin-top: 10px;
    }

    .left {
      width: 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      p {
        font-size: 1.2rem;
        line-height: 1.5;
        color: #494949;
        margin-bottom: 10px;
      }

      .connect {
        display: flex;
        flex-direction: column;
        gap: 20px;


        .connect-item {

          p {
            margin: 10px 0;
          }

          .links {
            display: flex;
            gap: 20px;
            flex-direction: column;
            margin-left: 20px;
          }
        }

        a {
          color: #4089AB;
          text-decoration: none;

          &:hover {
            color: #234a5c;
          }
        }
      }

      @media (max-width: 800px) {
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;

        p {
          font-size: 1rem;
        }
      }
    }

    .right {
      width: 40%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 800px) {
        width: 100%;
        justify-content: center;
        align-items: center;
      }

      .ant-spin-nested-loading {
        width: 100%;
      }

      form {
        width: 100%;
        display: flex;
        flex-direction: column;

        label {
          font-size: 1rem;
          margin-bottom: 5px;
        }

        input[type="text"],
        input[type="email"],
        textarea {
          border: 1px solid #4089AB;
          border-radius: 10px;
          padding: 15px;
          font-size: 1.1rem;
          margin-bottom: 20px;

          &::placeholder {
            color: #ffffff51;
          }
        }

        button {
          background-color: #4089AB;
          border: 1px solid #4089AB;
          border-radius: 10px;
          color: #fff;
          font-size: 1.1rem;
          font-weight: 600;
          transition: all 0.3s;
          height: 50px;
          width: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          gap: 10px;

          @media (max-width: 800px) {
            width: 100%;
          }

          &:hover {
            background-color: #2a5b72;
            border: 1px solid #2a5b72;
            transition: all 0.3s;
          }
        }
      }
    }
  }

  .contact-ends {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    margin-top: 100px;
    background-color: #1a0c29;
    padding: 70px 0;
    border-radius: 10px;

    @media (max-width: 800px) {
      margin-top: 50px;
    }

    h2 {
      font-size: 1.5rem;
      font-weight: 800;
      color: #fff;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      text-align: center;
      padding: 0 20px;

      @media (max-width: 800px) {
        font-size: 1.3rem;
      }
    }

    .connect {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 60px;
      margin-top: 20px;

      @media (max-width: 800px) {
        flex-direction: column;
        gap: 20px;
      }

      a {
        color: #fff;
        font-size: 1.1rem;
        transition: all 0.3s;
        text-decoration: none;

        &:hover {
          transform: scale(1.1);
          transition: all 0.3s;
          color: #bc82ff;
        }
      }

    }
  }
}